import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logo.svg';
import avatarImage from 'assets/avatar.jpg';
import './App.css';
import data from 'data.json';
import NavigationBar from 'components/navigationBar';
import MediaList from 'components/mediaList';
import MediaViewer from 'components/mediaViewer';

import { setScrollPosition, setMediaViewer, setActivePage } from 'actions';
import {getLocale, getLanguage} from 'components/localeText';
import { connect } from 'react-redux';

import Avatar from 'components/avatar';
import EmailIcon from 'assets/email.svg';
import YouTube from 'assets/youtube.svg';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      scrollBack: -1,
      pageID: 0
    }

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    if (this.props.match.params.id !== undefined || this.props.match.params.page !== undefined) {
      this.onRoute();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.mediaViewer !== null && this.props.mediaViewer === null) {
      this.setState({scrollBack: this.props.scrollTo})
    }

    if (prevState.scrollBack !== this.state.scrollBack && this.state.scrollBack !== -1) {
      window.scrollTo(0, this.state.scrollBack);
      this.setState({scrollBack: -1});
    }

    if (prevProps.match !== this.props.match) {
        this.onRoute();
    }
  }

  onScroll(e) {
    if (this.props.mediaViewer === null) {
      // Only update when mediaviewer is not active
      this.props.setScrollPosition(e.target.scrollingElement.scrollTop);
    }
  }

  renderContent() {
    if (this.props.mediaViewer !== null) {
      return (
        <MediaViewer history={this.props.history} />
      )
    } else {
      document.title = 'Ferdi Willemse / '+ getLanguage(this.props.page.title);
      return (
        <React.Fragment>
          <div className="pageDescription" dangerouslySetInnerHTML={getLocale(this.props.page.description)} />
          <MediaList history={this.props.history} />
        </React.Fragment>
      )
    }
  }

  onRoute() {
    const route = this.props.match.params.id;
    const pageRoute = this.props.match.params.page;
    const pages = this.props.data.pages;

    let foundPage = false;
    if (route !== undefined || pageRoute !== undefined) {
      pages.map((page, i) => {

        if (page.route === pageRoute) {
          this.props.setActivePage(i);
        }

        page.media.map(media => {
          if (media.seolink === route) {
            this.props.setMediaViewer(media);
            foundPage = true;
          }
        })
      });
    } else {
      this.props.setActivePage(0);
    }

    if (!foundPage) {
      this.props.setMediaViewer(null);
    }
  }

  onEmail(x) {
    x.preventDefault();
    const a = "ferdi";
    const b = "ferdiwillemse";
    const c = "com";
    const d = "@";
    const e = ".";
    const f = "mailto";
    const g = ":";
    window.location.href = `${f}${g}${a}${d}${b}${e}${c}`;
  }

  render() {
    const visibleScroll = (this.props.mediaViewer !== null) && ' noscroll' || '';

    return (
      <React.Fragment>
      <div className={`App${visibleScroll}`}>
        <div className="header">
          <Avatar image={avatarImage} />
          <Link to="/"><img width={214} height={20} src={logo} /></Link>
          <div className="contact">
            <a href="justarandomlink" rel="nofollow" onClick={this.onEmail} className="EmailIcon"><img src={EmailIcon} onClick={this.onEmail} /></a>
            <a href="https://www.youtube.com/channel/UCeGP0Fs3BOoCmPwI_2QsQHA/" target="_blank" className="YouTubeIcon" title="Ferdi Willemse on YouTube"><img src={YouTube} /></a>
          </div>
          <div className="profile--description" dangerouslySetInnerHTML={ this.getLanguage(data.profile.description) } />
        </div>
        <NavigationBar match={this.props.match} setPage={this.setActivePage} />
        { this.renderContent() }
      </div>
      </React.Fragment>
    );
  }

  getLanguage(prop) {
    if (navigator.language === 'nl') {
      if (prop.hasOwnProperty('nl')) {
        return {__html: prop.nl };
      } else {
        console.error('NL Language does not exist in this object')
        return '';
      }
    } else {
      if (prop.hasOwnProperty('en')) {
        return {__html: prop.en };
      } else {
        console.error('EN Language does not exist in this object')
        return '';
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    page: state.data.pages[state.page],
    mediaViewer: state.mediaViewer,
    scrollTo: state.scrollPosition,
    data: state.data,
    testdata: state.testdata
  }
}

export default connect(mapStateToProps, { setScrollPosition, setMediaViewer, setActivePage })(App);
