export function setActivePage(index) {
  return {
    type: 'SET_PAGE',
    payload: index
  }
}

export function setMediaViewer(mediaItem) {
  return {
    type: 'SET_MEDIAVIEWER',
    payload: mediaItem
  }
}

export function setScrollPosition(posY) {
  return {
    type: 'SET_SCROLLPOSITION',
    payload: posY
  }
}