import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setActivePage, setMediaViewer } from 'actions';

class NavigationBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      indicator: {
        left: 0,
        top: 0,
        width: 0
      }
    }
    this.setNavPosition = this.setNavPosition.bind(this);
    this.getLanguage = this.getLanguage.bind(this);
    this.setPage = this.setPage.bind(this);
    window.addEventListener('resize', this.onResize.bind(this), true);

  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setNavPosition();
    }
  }

  componentDidMount() {
    this.setNavPosition();
  }

  onResize(e) {
    this.setNavPosition();
  }

  setNavPosition() {
    if (this.links === null || this.links === undefined) {
      return;
    }
    
    const el = this.links.children[this.props.page];
    this.setState({
      indicator: {
        left: el.offsetLeft,
        top: el.offsetTop,
        width: el.offsetWidth
      }
    });
  }

  setPage(index, e) {

    this.props.setMediaViewer(null);
    this.props.setActivePage(index);
    //e.preventDefault();
  }

  isPageActive() {
    return (this.props.match.params.page === this.props.pages[this.props.page].route);
  }


  render() {
    return(
      <div className="navigationBar">

        <div style={{
          left: this.state.indicator.left,
          top: this.state.indicator.top,
          width: this.state.indicator.width
        }} className="indicator"></div>

        <div ref={(ref) => {this.links = ref;}} className="navigationBar--links">
          { this.props.pages.map((page, index) => {
            return <Link className={this.isPageActive() && 'active' || ''} key={index} onClick={(e) => this.setPage(index, e)} to={`/${page.route}`}>{this.getLanguage(page.title)}</Link>
          })}
        </div>
      </div>
    )
  }



  getLanguage(prop) {
    if (navigator.language === 'nl') {
      if (prop.hasOwnProperty('nl')) {
        return prop.nl;
      } else {
        console.error('NL Language does not exist in this object')
        return '';
      }
    } else {
      if (prop.hasOwnProperty('en')) {
        return prop.en;
      } else {
        console.error('EN Language does not exist in this object')
        return '';
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { pages: state.data.pages, page: state.page }
}

export default connect(mapStateToProps, {setActivePage, setMediaViewer})(NavigationBar);
