import { combineReducers } from 'redux';
import data from 'data.json';

const rootReducer = combineReducers({
  page: pageReducer,
  data: () => { return data },
  mediaViewer: mediaViewerReducer,
  scrollPosition: scrollPosition,
});

function pageReducer(state = 0, action) {
  switch (action.type) {
    case 'SET_PAGE':
      return action.payload;
    default:
      return state;
  }
}

function mediaViewerReducer(state = null, action) {
  switch(action.type) {
    case 'SET_MEDIAVIEWER':
      return action.payload;
    default:
      return state;
  }
}

function scrollPosition(state = 0, action) {
  switch(action.type) {
    case 'SET_SCROLLPOSITION':
      return action.payload;
    default:
      return state;
  }
}

export default rootReducer;
