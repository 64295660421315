import React, { Component } from 'react';
import MediaItem from 'components/mediaItem';
import { connect } from 'react-redux';

class MediaList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementHeight: 200
    }
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.setState({
      elementHeight: this.el.firstElementChild.offsetWidth
    })
  }

  render() {
    return(
      <div ref={(ref) => {this.el = ref}} style={{gridAutoRows: this.state.elementHeight}} className="mediaList">
        { this.props.media.map((item, index) =>
          <MediaItem history={this.props.history} key={index} page={this.props.page} item={item} />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    media: state.data.pages[state.page].media,
    page: state.data.pages[state.page]
  }
}

export default connect(mapStateToProps)(MediaList);
