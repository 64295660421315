import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getLocale, getLanguage} from 'components/localeText';
import { setMediaViewer } from 'actions';
import { connect } from 'react-redux';

class MediaViewer extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    document.title = 'Ferdi Willemse / ' + this.props.mediaViewer.title;
  }
  componentDidMount() {
    document.title = 'Ferdi Willemse / ' + this.props.mediaViewer.title;
  }

  renderVideo() {
    return(
      <React.Fragment>
        <div className="backButton" onClick={() => {
          this.props.setMediaViewer(null);
          this.props.history.push(`/${this.props.page.route}`);
        }}>{getLanguage(this.props.page.title)}</div>

        <div className="pageDescription" dangerouslySetInnerHTML={getLocale(this.props.mediaViewer.description)} />
        <div className="mediaViewer">
          <div onClick={() => this.props.setMediaViewer(null)} className="mediaViewer--close"></div>
          <div className="mediaViewer--container" dangerouslySetInnerHTML={{__html:this.props.mediaViewer.item}} />
        </div>
      </React.Fragment>
    );
  }

  renderImage() {
    return (
      <React.Fragment>
        <div className="backButton" onClick={() => {
          this.props.setMediaViewer(null);
          this.props.history.push(`/${this.props.page.route}`);
          }}>{getLanguage(this.props.page.title)}</div>

        <div className="pageDescription" dangerouslySetInnerHTML={getLocale(this.props.mediaViewer.description)} />

        <div className="mediaViewer">
          <img className="mediaViewer--image" src={this.props.mediaViewer.item} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { type } = this.props.mediaViewer;

    if (type === 'video') {
      return this.renderVideo();
    } else if (type === 'image') {
      return this.renderImage();
    } else if (type === 'link') {
      return this.renderImage();
    }else {
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    mediaViewer: state.mediaViewer,
    page: state.data.pages[state.page]
  }
}

export default connect(mapStateToProps, { setMediaViewer })(MediaViewer)
