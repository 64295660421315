import React from 'react';

export function getLanguage(prop) {
  if (navigator.language === 'nl') {
    if (prop.hasOwnProperty('nl')) {
      return prop.nl;
    } else {
      console.error('NL Language does not exist in this object')
      return '';
    }
  } else {
    if (prop.hasOwnProperty('en')) {
      return prop.en;
    } else {
      console.error('EN Language does not exist in this object')
      return '';
    }
  }
}

export function getLocale(prop) {
  if (navigator.language === 'nl') {
    if (prop.hasOwnProperty('nl')) {
      return {__html: prop.nl};
    } else {
      console.error('NL Language does not exist in this object')
      return '';
    }
  } else {
    if (prop.hasOwnProperty('en')) {
      return {__html: prop.en};
    } else {
      console.error('EN Language does not exist in this object')
      return '';
    }
  }
}

export default (props) => {
  return(
    <React.Fragment>
      {getLanguage(props.text)}
    </React.Fragment>
  )
}
