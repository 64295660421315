import React, { Component } from 'react';
import link from 'assets/link.svg';
import image from 'assets/image.svg';
import video from 'assets/video.svg';

import { setMediaViewer } from 'actions';
import { connect } from 'react-redux';

class MediaItem extends Component {

  constructor(props) {
    super(props);


    this.state = {
      itemType: image,
      locationPreventShowing: this.props.item.hasOwnProperty('preventLocation'),
      loadedClass: '',
      loadedImage: null
    }

    this.fadeIn = this.fadeIn.bind(this);
    this.setItemType = this.setItemType.bind(this);
    this.playVideo = this.playVideo.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
    this.onLoadItem = this.onLoadItem.bind(this);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
  }

  setItemType() {
    let itemType;

    switch (this.props.item.type) {
      case 'link':
        itemType = link;
        break;
      case 'image':
        itemType = image;
        break;
      case 'video':
        itemType = video;
        break;
      default:
        itemType = image;
    }

    this.setState({itemType: itemType});
  }

  componentDidMount() {

    this.setItemType();

    this.fadeIn();

    if (this.mediaItem !== undefined && this.mediaItem !== null) {
      this.mediaItem.removeEventListener('animationend', this.onAnimationEnd);
      this.mediaItem.addEventListener('animationend', this.onAnimationEnd);
    }

    this.checkPreventShowingOnLocation();
  }

  componentWillUnmount() {
    if (this.props.item.type === 'video' && this.video !== undefined && this.video !== null) {
      this.video.removeEventListener('loadeddata', this.onLoadItem);
    }

    if (this.mediaItem !== undefined && this.mediaItem !== null) {
      this.mediaItem.removeEventListener('animationend', this.onAnimationEnd);
      this.mediaItem.addEventListener('animationend', this.onAnimationEnd);
    }

    this.setState({loadedClass: ''});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item !== this.props.item) {

      this.setItemType();

      if (this.mediaItem !== undefined && this.mediaItem !== null) {
        this.mediaItem.style.opacity = 0;
        this.mediaItem.classList.remove('itemLoaded');
      }


      if (this.video !== undefined && this.video !== null) {
        this.video.load();
      }

      this.checkPreventShowingOnLocation();
    }
  }

  fadeIn() {
    //console.log(this.props.item.seolink, this.video, this.props.item.type)
    if (this.props.item.type === 'video' && this.video !== undefined && this.video !== null) {
      this.video.addEventListener('loadeddata', this.onLoadItem);
      //console.log('-',this.props.item.seolink)
    }

    if (this.props.item.type === 'image') {
      let newImg = new Image();
      newImg.addEventListener('load', () => {this.onLoadItem();});
      newImg.src = this.props.item.thumb;
      //this.setState({loadedImage: newImg});
    }
  }

  onAnimationEnd() {
    this.mediaItem.style.opacity = 1;
    this.mediaItem.classList.remove('itemLoaded');
  }

  onLoadItem() {
    if (this.mediaItem !== undefined && this.mediaItem !== null)
      this.mediaItem.classList.add('itemLoaded');
  }

  checkPreventShowingOnLocation() {
    if (this.props.item.hasOwnProperty('preventLocation')) {
      this.setState({
        locationPreventShowing: true
      });

      fetch('/country.php').then(response => response.json(), error => console.log(error))
      .then(json => {
        if (json !== undefined && json.country_code !== this.props.item.preventLocation) {
          this.setState({
            locationPreventShowing: false
          });
          this.fadeIn();
        }
      });
    } else {
      this.setState({
        locationPreventShowing: false
      });
      this.fadeIn();
    }
  }

  playVideo(e) {
    if (this.video !== undefined && this.video !== null) {
      this.video.play();
    }
  }

  stopVideo(e) {
    /*if (this.video !== undefined && this.video !== null) {
      this.video.pause();
    }*/
  }

  createVideo() {
    const { item } = this.props;
    if (item.type === 'video') {

      const zoom = (item.thumbvideo.hasOwnProperty('zoom') && item.thumbvideo.zoom === true) && ' zoom' || '';

      return (
        <video className={`mediaElement--video${zoom}`} autoPlay loop muted ref={ref => {this.video = ref}} playsInline={true}>
          <source src={item.thumbvideo.webm} type="video/webm" />
          <source src={item.thumbvideo.mp4} type="video/mp4" />
        </video>
      )
    }
  }

  navigateToItem(item, e) {
    if (item.type !== 'link') {
      this.props.setMediaViewer(item);
      this.props.history.push(`/${item.seolink}`)
    } else {
      e.preventDefault();
      window.open(item.item,'_blank');
    }
  }

  render() {
    const { item } = this.props;

    if (this.state.locationPreventShowing) {
      return null;
    } else {
      return(
        <div ref={ref => {this.mediaItem = ref}} onClick={(e) => { this.navigateToItem(item, e);}} className={`mediaElement`}>
          <a href={`${this.props.page.route}/${item.seolink}`}>
            <div style={{backgroundImage: `url(${item.thumb})`}} className="mediaElement--inner">
              { this.createVideo() }
            </div>
            <img src={this.state.itemType} />
          </a>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    mediaViewer: state.mediaViewer
  }
}

export default connect(mapStateToProps, { setMediaViewer })(MediaItem)
